<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <img :src="logotipo" height="32" class="ml-7" :alt="title" />
    </v-app-bar>

    <v-main class="grey lighten-3" style="padding: 0px 0px 0px;">
      <v-container>
        <div class="form-wrapper form-v1">
          <div class="form-inner">
            <v-card>
              <v-card-text v-if="componenteInicial">
                <div class="page-title text-center px-5 mt-10">
                  <h2 class="text-2xl font-weight-semibold text--primary">
                    Formulário de pesquisa de clima organizacional
                  </h2>
                  <p class="text-sm">
                    Para começar a pesquisa clique em <b>iniciar</b>, caso já
                    tenha iniciado digite o seu código e clique em
                    <b>buscar</b>!
                  </p>
                </div>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      placeholder="Digite o código do seu formulário"
                      v-model="token"
                      label="Código Formulário"
                      class="mb-3"
                      :disabled="disabledControl.input || loading == true"
                      v-mask="'###-###'"
                      max="7"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      block
                      color="primary mr-2"
                      @click="buscarDadosFormulario()"
                      :disabled="
                        disabledControl.buscar || token == '' || loading == true
                      "
                      :loading="loadingControle.buscar"
                    >
                      Buscar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      block
                      outlined
                      color="error"
                      @click="iniciarPesquisa()"
                      :disabled="
                        disabledControl.iniciar ||
                        token != '' ||
                        pesquisa.uuid == null
                      "
                      :loading="loading"
                    >
                      Iniciar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="componenteFormulario">
                <h1 class="font-weight-medium text-base me-2 text-truncate">
                  Formulário de pesquisa
                </h1>
                <p class="text-sm">#Código: {{ resolvedCode(code) }}</p>
                <estrutura-formulario v-if="perguntas" />
              </v-card-text>
            </v-card>
          </div>
          <ModalPesquisaVencida />
          <ModalPesquisaCodigo />
          <v-overlay :value="loadingEstruturaFormulario">
            <div class="d-flex flex-column justify-center align-center">
              <v-progress-circular indeterminate color="#001325" size="75">
                <img :src="logotipoMenor" height="45" alt="logo da legiio" />
              </v-progress-circular>
              <p class="mt-2" color="primary">Carregando informações</p>
            </div>
          </v-overlay>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import ModalPesquisaCodigo from "../components/ModalPesquisaCodigo.vue";
import logo from "@/assets/images/svg/logo_legiio.svg";
import logo_m from "@/assets/images/logos/legiio.png";

export default {
  name: "Formulario",
  inject: ["$validator"],
  components: {
    EstruturaFormulario: () => import("../components/EstruturaFormulario.vue"),
    ModalInformacoes: () => import("../components/ModalInformacoes.vue"),
    ModalFinalizacao: () => import("../components/ModalFinalizacao.vue"),
    ModalPesquisaVencida: () =>
      import("../components/ModalPesquisaVencida.vue"),
    ProgressBar: () => import("../components/ProgressBar.vue"),
    PaginaErro: () => import("../components/PaginaErro.vue"),
    PerfectScrollbar,
    ModalPesquisaCodigo,
  },
  data() {
    return {
      logotipo: logo,
      logotipoMenor: logo_m,
      title: this.$vuetify.breakpoint.mobile
        ? "Legiio"
        : "Legiio - Gestão Estratégica de Pessoas",
      pesquisa: {
        uuid: null,
      },
      token: "",
      loadingControle: {
        buscar: false,
        iniciar: false,
      },
      disabledControl: {
        buscar: false,
        iniciar: false,
        input: false,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    initialize() {
      if (this.pesquisa.uuid) {
        this.$store.dispatch("pesquisas/buscarPesquisaExterna", {
          uuid: this.pesquisa.uuid,
        });
      } else {
        this.$store.dispatch("snackBarModule/openSnackBar", {
          color: "error",
          timeout: 5000,
          text: "Informações inválidas",
        });
      }
    },
    buscarDadosFormulario() {
      if (this.token && this.token.length == 7) {
        this.loadingControle.buscar = true;
        this.disabledControl.input = true;
        const codigoFormulario = this.token.split("-").join("");
        this.$store
          .dispatch("pesquisas/retornaDadosPesquisaExternaPorToken", {
            code: codigoFormulario,
          })
          .then(() => {})
          .finally(() => {
            this.loadingControle.buscar = false;
            this.disabledControl.input = false;
          });
      } else {
        this.$store.dispatch("snackBarModule/openSnackBar", {
          color: "error",
          timeout: 5000,
          text: "Código inválido",
        });
      }
    },
    iniciarPesquisa() {
      this.initialize();
    },
    resolvedCode(item) {
      let result = item.split("");
      return `${result[0] + result[1] + result[2]}-${
        result[3] + result[4] + result[5]
      }`;
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["pesquisas/getLoadingPesquisaExterna"];
      },
    },
    formulario: {
      get() {
        return this.$store.getters["pesquisas/getHabilitarFormulario"];
      },
      set() {
        this.$store.dispatch("pesquisas/setHabilitarFormulario", value);
      },
    },
    erroPesquisa: {
      get() {
        return this.$store.getters["pesquisas/getErrorPesquisa"];
      },
    },
    componenteInicial: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteInicial"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteInicial",
          value
        );
      },
    },
    componenteFormulario: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteFormulario"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteFormulario",
          value
        );
      },
    },
    code: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaCodigoExternaMessage"];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaCodigoExternaMessage",
          value
        );
      },
    },
    perguntas: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaPerguntas"];
      },
      set() {
        this.$store.dispatch("pesquisas/setPesquisaExternaPerguntas", value);
      },
    },
    initializePesquisa: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaPerguntas"];
      },
      set() {
        this.$store.dispatch("pesquisas/setPesquisaExternaPerguntas", value);
      },
    },
    loadingEstruturaFormulario: {
      get() {
        return this.$store.getters[
          "pesquisas/getPCOLoadingEstruturaFormulario"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPCOLoadingEstruturaFormulario",
          value
        );
      },
    },
  },
  created() {
    /**
     * Verifica se o usuário possui sessões do formulário aberto no browser
     */
    this.loadingEstruturaFormulario = true;
    this.pesquisa.uuid = this.$route.params.uuid;
    this.$store.dispatch("pesquisas/initializePesquisaExterna", {
      uuid: this.pesquisa.uuid,
    });
  },
};
</script>

<style lang="scss">

.form-wrapper {
  display: flex;


  &.form-v1 {
    justify-content: center;
    overflow: hidden;
    padding: 1rem;

    .form-inner {
      width: 80rem;
      z-index: 0;
    }
  }
}

</style>
