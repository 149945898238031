<template>
  <div>
    <!-- <v-dialog v-model="dialog" persistent max-width="850">
      <v-card>
        <v-card-title class="d-flex flex-column justify-center align-center">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mt-10"
            icon
            size="50"
          >
            <v-icon size="2rem" color="primary"> mdi-exclamation-thick </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 font-weight-medium">Informações</h6>
        </v-card-title>
        <v-card-text>
          <p class="text-justify mb-7">
            O código abaixo refere-se ao número do seu formulário, caso precise
            parar de responder e continuar depois, o mesmo pode ser utilizado
            para recuperar as respostas e continuar de onde parou. Então
            memorize ou anote o código.
          </p>

          <p class="text-2xl font-weight-semibold primary--text mb-0 mt-8 text-center">
            {{ resolvedCode(code) }}
          </p>
          <p class="text-justify" v-html="descricao"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-4" @click="iniciarPesquisa()">
            Iniciar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="850"
    >
      <v-card>
        <v-card-title>
          <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mt-3"
            icon
            size="45"
          >
            <v-icon size="2rem" color="primary"> mdi-exclamation-thick </v-icon>
          </v-avatar>
          <h6 class="text-xl mt-4 ml-5 font-weight-medium">Informações</h6>
        </v-card-title>
        <v-card-text>
          <p class="text-justify mb-7">
            O código abaixo refere-se ao número do seu formulário, caso precise
            parar de responder e continuar depois, o mesmo pode ser utilizado
            para recuperar as respostas e continuar de onde parou. Então
            memorize ou anote o código.
          </p>

          <p class="text-2xl font-weight-semibold primary--text mb-8 mt-8 text-center">
            {{ resolvedCode(code) }}
          </p>
          <p class="text-justify" v-html="descricao"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-4" @click="iniciarPesquisa()">
            Iniciar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "modal-pesquisa-finalizada",
  data: () => ({}),
  methods: {
    resolvedCode(item) {
      let result = item.split("");
      return `${result[0] + result[1] + result[2]}-${
        result[3] + result[4] + result[5]
      }`;
    },
    iniciarPesquisa() {
      this.dialog = false;
      this.componenteFormulario = true;
      this.componenteInicial = false;
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaCodigoDialog"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaCodigoDialog", value);
      },
    },
    code: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaCodigoExternaMessage"];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaCodigoExternaMessage",
          value
        );
      },
    },
    descricao: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExternaDescricao"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisaExternaDescricao", value);
      },
    },
    componenteFormulario: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteFormulario"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteFormulario",
          value
        );
      },
    },
    componenteInicial: {
      get() {
        return this.$store.getters[
          "pesquisas/getPesquisaExternaComponenteInicial"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setPesquisaExternaComponenteInicial",
          value
        );
      },
    },
  },
};
</script>

<style>
svg#freepik_stories-self-confidence:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-self-confidence.animated #freepik--hand-4--inject-20 {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}
svg#freepik_stories-self-confidence.animated #freepik--hand-3--inject-20 {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}
svg#freepik_stories-self-confidence.animated #freepik--hand-2--inject-20 {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}
svg#freepik_stories-self-confidence.animated #freepik--hand-1--inject-20 {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
