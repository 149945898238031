import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","scrollable":"","max-width":"850"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text mt-3",attrs:{"color":"primary","icon":"","size":"45"}},[_c(VIcon,{attrs:{"size":"2rem","color":"primary"}},[_vm._v(" mdi-exclamation-thick ")])],1),_c('h6',{staticClass:"text-xl mt-4 ml-5 font-weight-medium"},[_vm._v("Informações")])],1),_c(VCardText,[_c('p',{staticClass:"text-justify mb-7"},[_vm._v(" O código abaixo refere-se ao número do seu formulário, caso precise parar de responder e continuar depois, o mesmo pode ser utilizado para recuperar as respostas e continuar de onde parou. Então memorize ou anote o código. ")]),_c('p',{staticClass:"text-2xl font-weight-semibold primary--text mb-8 mt-8 text-center"},[_vm._v(" "+_vm._s(_vm.resolvedCode(_vm.code))+" ")]),_c('p',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.descricao)}})]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mt-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.iniciarPesquisa()}}},[_vm._v(" Iniciar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }